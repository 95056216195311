import Popup from 'reactjs-popup';
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import GitHubIcon from '@material-ui/icons/GitHub'
import OpenInNew from '@material-ui/icons/OpenInNew'
import ArticleIcon from '@material-ui/icons/Description'
import RightArrow from '@material-ui/icons/ArrowRight'
import '../../App.css'
import './ProjectContainer.css'

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <div style={{minHeight : '150px', maxHeight : '250px', overflow: 'hidden'}}>
      <h3>{project.title}<br/><br/><br/></h3>
    </div>
    <br/>
      <Grid style={{ display: "flex"}}  
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center">

      <Typography><div style={{fontWeight: 'bold',  display: 'flex', alignItems: 'center'}}>{project.starts_at.month} / {project.starts_at.year} <RightArrow/> {project.ends_at.month} / {project.ends_at.year}</div></Typography>
      <br/><br/>
      <Grid style={{ display: "flex"}}>
        {project.description && (
            <><Popup trigger={<button className='button hover-underline-animation' type='button' style={{fontWeight: 'bold',  display: 'flex', alignItems: 'center'}}> <ArticleIcon /> &nbsp; Description</button>} modal>
            {close => (
              <div className="app modal project__stack-item">
                <button className="close" type='button' onClick={close}>
                  &times;
                </button>
                <div className="content">
                  {project.description}
                </div>
              </div>
            )}
          </Popup></>
        )}
      </Grid>

      </Grid>
    <br/>

    <Grid style={{ display: "flex"}}  
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center">

    {project.git_url && (
      <a
        href={project.git_url}
        aria-label='source code'
        className='link--icon hover-underline-animation'
        style={{fontWeight: 'bold',  display: 'flex', alignItems: 'center'}}
      >
        <GitHubIcon /> &nbsp; GitHub
      </a>
    )}

    {project.url && (
      <a
        href={project.url}
        aria-label='source code'
        className='link--icon hover-underline-animation'
        style={{fontWeight: 'bold',  display: 'flex', alignItems: 'center'}}
      >
        <OpenInNew /> &nbsp; Link
      </a>
    )}
    </Grid>
  </div>
)

export default ProjectContainer
