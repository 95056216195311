import data from './Data/data.json'

const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://www.sahajpatel.tech/',
  title: 'Home',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Sahaj Patel',
  role: 'Engineer',
  descriptors: ['Roboticist', 'Explorer', 'Designer', 'Prototyper', 'Student'],
  description: data.summary,
  resume: 'https://www.linkedin.com/in/sahajpatel/',
  social: {
    linkedin: 'https://www.linkedin.com/in/sahajpatel/',
    github: 'https://github.com/sah4jpatel',
  },
}

const projects = data.accomplishment_projects

// const projects = [
//   // projects can be added an removed
//   // if there are no projects, Projects section won't show up
//   {
//     name: 'Greyscale Image Colorizer Model',
//     description:
//       'This is a project built on PyTorch with CUDA acceleration to explore the use of convolutional nueral networks to synthesize color data from grayscale data to colorize images.',
//     stack: ['PyTorch', 'Jupyter', 'Python'],
//     sourceCode: 'https://github.com/sah4jpatel/Image-Colorizer-Model',
//     livePreview: 'https://github.com',
//     modaltitle: 'Title',
//     modaldesc: 'Hello world! This is the coolest project ever and I am excited to continue my work. Hopefull this website becomes something beautiful...',
//   },
//   {
//     name: 'Real-Time ASL Character Classifier',
//     description:
//       'This project combines various ML technologies with Convolutional Neural Networks to predict the individual alphabetical characters of the American Sign Language, all in real time.',
//     stack: ['OpenCV', 'Python', 'Torch'],
//     sourceCode: 'https://github.com/Gabinson200/ASL',
//     livePreview: 'https://github.com',
//   },
//   {
//     name: 'Grasp: A Learning Enhancement Tool',
//     description:
//       'Grasp uses OCR and NLP models to extract crucial information from lecture videos and reformatting such in order to improve a student\'s learning efficieny and efficacy.',
//     stack: ['Google VisionAI', 'SpaCy', 'Python'],
//     sourceCode: 'https://github.com/alago1/grasp',
//     livePreview: 'https://github.com',
//   },
// ]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python',
  'C++',
  'MATLAB/MEX',
  'OpenCV',
  'PyTorch',
  'Keras',
  'Jupyter',
  'Fusion 360',
  'Git',
  'CI/CD',
  'Agile'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'me@sahajpatel.tech',
}

export { header, about, projects, skills, contact }
