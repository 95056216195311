import { useContext, useState } from 'react'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeContext } from '../../contexts/theme'
import { projects, skills, contact } from '../../portfolio'
import './Navbar.css'

const revealNavHamburger = () => {
  const ActionBar = document.getElementById('ActionBar');
  const ActionNav = document.getElementById('action_nav');
  if (window.scrollY > 60) {
    ActionBar.className = 'btn btn--icon nav__actionbar show';
    ActionNav.className = 'nav__modal show';
  } else {
    ActionBar.className = 'btn btn--icon nav__actionbar hide';
    ActionNav.className = 'nav__modal hide';
  }
};

const Navbar = () => {
  const [{ themeName, toggleTheme }] = useContext(ThemeContext)
  const [showNavList, setShowNavList] = useState(false)
  const [showActionList, setShowActionList] = useState(false)

  const toggleNavList = () => setShowNavList(!showNavList)
  const toggleActionList = () => setShowActionList(!showActionList)

  window.addEventListener("scroll", revealNavHamburger);

  return (
    <nav className='center nav'>
      <ul
        id='NavList'
        style={{ display: showNavList ? 'flex' : null }}
        className='nav__list'
      >
        {projects.length ? (
          <li className='nav__list-item'>
            <a
              href='#work'
              onClick={toggleNavList}
              className='link link--nav'
            >
              My Work
            </a>
          </li>
        ) : null}

        {skills.length ? (
          <li className='nav__list-item'>
            <a
              href='#skills'
              onClick={toggleNavList}
              className='link link--nav'
            >
              My Life
            </a>
          </li>
        ) : null}

        {contact.email ? (
          <li className='nav__list-item'>
            <a
              href='#contact'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Contact
            </a>
          </li>
        ) : null}
      </ul>

      <button
        type='button'
        onClick={toggleTheme}
        className='btn btn--icon nav__theme'
        aria-label='toggle theme'
      >
        {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
      </button>

      <button
        id='NavHamburger'
        type='button'
        onClick={toggleNavList}
        className='btn btn--icon nav__hamburger'
        aria-label='toggle navigation'
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </button>

      <button
        id='ActionBar'
        type='button'
        onClick={toggleActionList}
        className='btn btn--icon nav__actionbar'
        aria-label='toggle actionbar'
      >
        {showActionList ? <CloseIcon fontSize='large'/> : <MenuIcon fontSize='large'/>}
      </button>

      <div id="action_nav" className="nav__modal hide" style={{ display: showActionList ? null : 'none' }}>
        <div className="action_nav_content">
          <div className='link_bg'><a href="#top" onClick={toggleActionList} className="link__action">Home</a></div><br/>
          <div className='link_bg'><a href="#work" onClick={toggleActionList} className="link__action">My Work</a></div><br/>
          <div className='link_bg'><a href="#skills" onClick={toggleActionList} className="link__action">My Life</a></div><br/>
          <div className='link_bg'><a href="#contact" onClick={toggleActionList} className="link__action">Contact</a></div><br/>
        </div>
      </div>

    </nav>
  )
}

export default Navbar