import {useState} from 'react'
import uniqid from 'uniqid'
import Popup from 'reactjs-popup'
import ArticleIcon from '@material-ui/icons/Description'
import { Typography } from "@material-ui/core"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import WorkIcon from '@material-ui/icons/Work';
import data from '../../Data/data.json'
import { skills, projects } from '../../portfolio'
import ProjectContainer from '../ProjectContainer/ProjectContainer'
import './Work.css'

const workhistory = data.experiences

const Work = () => {  
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <><section id='work' className='section projects'>
      <h2 className='section__title'>My Work</h2>

      <div>
        <VerticalTimeline layout="2-columns">
          {workhistory.map((histItem) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'var(--clr-fg)', color: 'var(--clr-bg)' }}
              contentArrowStyle={{ borderRight: '12px solid  var(--clr-fg)' }}
              // eslint-disable-next-line prefer-template
              date={"" + histItem.starts_at.month + "/" + histItem.starts_at.year + " - " + (histItem.ends_at ? (histItem.ends_at.month + "/" + histItem.ends_at.year) : "Present")}
              dateClassName="date"
              iconStyle={{ background: 'var(--clr-fg)', color: '#fff' }}
              icon={<WorkIcon />}>
              <h3 className="vertical-timeline-element-title">{histItem.title}</h3> <br />
              <h4 className="vertical-timeline-element-subtitle">{histItem.company}</h4>

              {histItem.description &&
                (<><Popup trigger={<button className='dot' type='button'> <ArticleIcon className='cent' /></button>} modal>
                  {close => (
                    <div className="app modal project__stack-item">
                      <button className="close" type='button' onClick={close}>
                        &times;
                      </button>
                      <div className="content">
                        {histItem.description}
                      </div>
                    </div>
                  )}
                </Popup></>
                )}

            </VerticalTimelineElement>))}
        </VerticalTimeline>
      </div>
      <br />
      <div style={{ minHeight: '50px', maxHeight: '50px', overflow: 'hidden' }} />
      <h3 className='section__title'>Projects</h3>
      <div className='projects__grid'>
        {projects.map((project) => (
          <ProjectContainer key={uniqid()} project={project} />
        ))}
      </div>
    </section><><section className='section skills' id='skills'>
      <h3 className='section__title'>Skills</h3>
      <ul className='skills__list'>
        {skills.map((skill) => (
          <li key={uniqid()} className='skills__list-item btn btn--plain'>
            {skill}
          </li>
        ))}
      </ul>
    </section></></>


  )
}

export default Work
