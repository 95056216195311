import uniqid from 'uniqid'
import { skills } from '../../portfolio'
import './Life.css'

const Life = () => {
  if (!skills.length) return null

  return (
    <section className='section skills' id='skills'/>
  )
}

export default Life
