import { Carousel } from 'react-responsive-carousel';
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TextLoop from "react-text-loop";
import { about } from '../../portfolio'
import car1 from '../Media/img (1).PNG'
import car2 from '../Media/img (2).PNG'
import car3 from '../Media/img (3).PNG'
import car4 from '../Media/img (4).PNG'
import car5 from '../Media/img (5).PNG'
import car6 from '../Media/img (6).PNG'
import car7 from '../Media/img (7).PNG'
import car8 from '../Media/img (8).PNG'
import car9 from '../Media/img (9).PNG'
import car10 from '../Media/img (10).PNG'
import car11 from '../Media/img (11).PNG'
import './About.css'

const About = () => {
  const { name, role, descriptors, description, social } = about

  return (
    <div className='about'>
        <div className='about__img'>
          <Carousel selectedItem={Math.floor(Math.random() * 10)+1} showArrows={false} showIndicators={false} showThumbs={false} showStatus={false}
          infiniteLoop autoFocus autoPlay stopOnHover={false} interval={15000} width="30%"  className="caro" transitionTime={1000}>
            <div>
              <img src={car1} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car2} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car3} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car4} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car5} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car6} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car7} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car8} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car9} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car10} className='img' alt="Animoji"/>
            </div>
            <div>
              <img src={car11} className='img' alt="Animoji"/>
            </div>
          </Carousel>
        </div>

        <h1 className='about__role'>Hi, I am <br/><span className='about__name'>{name}.</span></h1>

        <h2 className='about__role'>{role.concat(" + ")}
        <TextLoop springConfig={{ stiffness: 180, damping: 8 }} fade >
          {descriptors.map((descriptor) => (
            <span className='abour__role__alt' key={descriptor}>{descriptor}</span>
          ))}
        </TextLoop>
        </h2>

        <div className='about__contact'>
          {social && (
            <>
              {social.github && (
                <a
                  href={social.github}
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label='github'
                  className='link link--icon'
                >
                  <GitHubIcon />
                </a>
              )}

              {social.linkedin && (
                <a
                  href={social.linkedin}
                  target="_blank"
                  rel="noreferrer noopener"
                  aria-label='linkedin'
                  className='link link--icon'
                >
                  <LinkedInIcon />
                </a>
              )}
            </>
          )}
        </div>
        
        <p className='about__role about__desc__alt'>{description && description}</p>

      </div>
  )
}

export default About
