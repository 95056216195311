import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a
      href='https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley'
      className='link footer__link'
    >
      This is what makes me Sahaj Patel.
    </a>
  </footer>
)

export default Footer
